
<script setup lang="ts">
import { onMounted, reactive, ref, defineEmits } from "vue"
import { type DataObject } from "o365-dataobject";
import TreeObject from "o365-data-tree";

import Nav from './Register.FolderView.Nav.vue';
import DocumentsList from './Register.FolderView.DocumentsList.vue';
import FolderChooser from './Register.FolderView.FoldersDropDown.vue';

import { MListView, MListItem } from 'o365-mobile';
import { GroupByFolders, getTreeObject, TreeNode } from "o365-data-tree";

export interface IProps {
    dataObject: DataObject;
    folderLevel: number;
};

const props = defineProps<IProps>();
const emits = defineEmits<{
    (e: "setFolderLevel", level: number);
}>();

// this variable contains current level of choosen folder. using 
const folderLevel = ref<number>(0);
const isFolderViewExists = ref<boolean>(true);
const isLoading = ref<boolean>(true);

// isCurrentFolderNotExpandble var represents last level of the folders structure 
// when it's time to show documnets. true - show docs, false - show folders.
const isCurrentFolderNotExpandble = ref<boolean>(false);

// tree
const groupsByFolders: GroupByFolders = reactive(new GroupByFolders(props.dataObject, "AccessIdPath"));
const foldersTree = ref<TreeNode[]>([]);
const tree = ref<TreeObject>(null);
const historyMap = ref<string[]>([]);

//                            row : TreeNode
const onRowClick = async (row): Promise<void | undefined> => {
    if (props.dataObject.state.isLoaded && row.ID) {
        openDocument(row);

        return;
    }

    // show docs if no more folder avalible.
    if (!row.expandable) {
        isCurrentFolderNotExpandble.value = true;
        await groupsByFolders.applyGroupByFilter(row);
    }

    isLoading.value = true;
    // disable groupByFolders dropdown if any folder was opened
    folderLevel.value = row.level;
    emits("setFolderLevel", folderLevel.value);

    // expand choosen node - literaly open choosen folder.
    tree.value.node.nodeClick(row);

    // Save folder name to show at History breadcrumb
    historyMap.value.push(row?.item?.value ?? "Blanc");

    // update groupsByFolders and set layout of choosen folder 
    await groupsByFolders.expand(row);
    foldersTree.value = row.children;

    isLoading.value = false;
}

const openDocument = (row): string => {
    let link: string = "";

    if (row.ID) {
        link = `/nt/documentregister/document?ID=${row.ID}`;
        return window.location.href = link;
    }

    console.error(`Document was not oppend because of provided row has been wrong` +
        "or it's out of data at all!");
    throw new Error("Document was not oppend because of provided row has no ID " +
        "or it's out of data at all!");
}



// using in FolderChooser comp to switch groups and save choosen one.
const changeFolderGroup = async (event): Promise<void> => {
    isLoading.value = true;
    await groupsByFolders.selectGroupByByIndex(parseInt(event?.target.value));

    tree.value = await getTreeObject(groupsByFolders.current);
    foldersTree.value = await tree.value.node.initChildren({ filterString: "" });

    isLoading.value = false;
}

// go up on folder hierarchy handling.
const goBack = async (): Promise<void> => {
    if (tree.value.currentNode) {
        historyMap.value.pop();

        isLoading.value = true;

        if (!tree.value.currentNode.expandable) {
            isCurrentFolderNotExpandble.value = false;
        }

        emits("setFolderLevel", --folderLevel.value);

        tree.value.node.nodeClick(tree.value.currentNode.parent);
        await groupsByFolders.expand(tree.value.currentNode);

        foldersTree.value = tree.value.currentNode.children;

        isLoading.value = false;
    }
}

const addSpaceBeforeSecondUppercase = (str: string): string => str.replace(/([a-z])([A-Z])/g, '$1 $2');

// initing a tree and sets dropdown folder to first.
onMounted(async () => {
    groupsByFolders.initialize();

    // throw an error if no matches on provided view in initFolders method.
    try {
        await groupsByFolders.initFolders(0);
        tree.value = await getTreeObject(groupsByFolders.current);
        foldersTree.value = await tree.value.node.initChildren({ filterString: "" });

        isLoading.value = false;
    } catch (e) {
        // set that folder view for the data source doesn't exist to show the error
        isFolderViewExists.value = false;
        isLoading.value = false;
    }

    window.groupsByFolders = groupsByFolders;
});

</script>

<template>
    <FolderChooser :groupsByFolders="groupsByFolders" :folderLevel="folderLevel ?? 0"
        @changeFolderGroup="changeFolderGroup" />
    <Nav v-if="historyMap.length > 0" @goBack="goBack" :historyMap="historyMap" />
    <div v-if="folderLevel !== groupsByFolders?.current?.fieldsConfigs?.size" class="subtitle" id="subTitle">
        <span> {{ addSpaceBeforeSecondUppercase(groupsByFolders?.current?.fieldsConfigs?.get(folderLevel + 1)?.fieldName ?? "") }}</span>
    </div>
    <!-- show spinner only for items list, not for folder. On folder page using spinner from MContainer component -->
    <m-content :showSpinner="isLoading && dataObject.state.isLoaded || dataObject.state.isLoading && folderLevel == groupsByFolders?.current?.fieldsConfigs?.size">

        <template v-if="dataObject.state.isLoaded">

            <MListView v-if="!isCurrentFolderNotExpandble && !isLoading" :ignoreContentAbsence="!isLoading && foldersTree.length > 0"
                :data-object="dataObject" noSearch @select="onRowClick">
                <template v-if="!isCurrentFolderNotExpandble">
                    <template v-for="row in foldersTree">
                        <slot name="mobileItem" :row="row">
                            <MListItem :row="row">
                                <i class="bi bi-folder"></i>
                                {{ row.item.value ?? "Blanc" }} ({{ row.item.count }})
                            </MListItem>
                        </slot>
                    </template>
                </template>
            </MListView>

            <template v-if="isCurrentFolderNotExpandble && !dataObject.state.isLoading">
                <template v-for="row in props.dataObject.data">
                    <div style='margin:10px'>
                        <slot name="card" :row="row">
                            {{ "row " + row.ID }}
                        </slot>
                    </div>
                </template>
            </template>


            <div class="text-center" style="margin-top: 50%" v-if="!isFolderViewExists">
                <span class="fs-2 text">No Folder View avalible for this app!</span>
            </div>
        </template>
    </m-content>
</template>

<style>
.blue {
    color: blue;
}

.green {
    color: green;
}

.orange {
    color: orange;
}

.red {
    color: red;
}

.subtitle {
    font-size: 1em;
    padding: 0 1em;
    border-bottom: 1px solid var(--bs-gray-300);
    font-weight: 500;
    margin-top: 1rem;
}

.navigation {
    max-height: 500px;
    display: flex;
    flex-wrap: wrap;

    font-size: 1em;
    /* border-bottom: 1px solid var(--bs-gray-300); */
    padding: 0 1.2rem;


    & .navigation__item {
        display: flex;
        align-items: center;

        & .navigation__item__btn {
            height: 70%;
            background: none;
            border: none;
            font-weight: 500;

            & .navigation__item__btn__text {
                color: (var(--bs-primary-rgb), var(--bs-text-opacity));
            }
        }

        :nth-child(n+1) {
            padding: 0 5px;
        }

        :first-child {
            padding: 0;
            padding-right: 5px;
        }

        & .navigation__item__arrow {
            align-self: center;
        }
    }
}

.listWrapper {
    width: 100%;
    position: relative;
    flex-grow: 1;
    /* margin-top: 30px; */
    padding: 0 1rem
}
</style>